
import { Options, prop, Vue } from "vue-class-component";
import Checkbox from "primevue/checkbox";

class Props {
  value = prop<boolean>({
    default: false,
    type: Boolean
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
  label = prop<string>({
    default: "",
    type: String
  });
  hint = prop<string>({
    default: "",
    type: String
  });
  textSize = prop<number>({
    default: 12,
    type: Number
  });
  keyName = prop<string>({
    default: "",
    type: String
  });
  labelWeight = prop<string>({
    default: "semibold",
    type: String
  });
  className = prop<string>({
    default: "p-field-checkbox",
    type: String
  });
}
@Options({
  emits: ["update:value"],
  components: {
    Checkbox
  }
})
export default class CheckBoxV2 extends Vue.with(Props) {
  valueChecked = this.value;
  onChecked() {
    this.$emit("update:value", this.valueChecked);
  }

  setDefault() {
    this.valueChecked = false;
  }
}
