import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createBlock("div", {
    class: `${_ctx.className} text-left`
  }, [
    _createVNode(_component_Checkbox, {
      id: _ctx.keyName,
      modelValue: _ctx.valueChecked,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.valueChecked = $event)),
      onChange: _ctx.onChecked,
      binary: true,
      disabled: _ctx.disabled
    }, null, 8, ["id", "modelValue", "onChange", "disabled"]),
    _createVNode("label", {
      class: 
        `ml-2 text-px xxl:text-${_ctx.textSize}px text-black-lp-100 font-${_ctx.labelWeight}`
      
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _withDirectives(_createVNode("span", { class: "text-gray-lp-500 font-normal" }, _toDisplayString(_ctx.hint), 513), [
        [_vShow, _ctx.hint]
      ])
    ], 2)
  ], 2))
}